// ***************** Colors *****************

//existing colors: https://www.rapidtables.com/web/css/css-color.html#gray

// Primary Colors
$as-blue: #156cf0;
$as-dark-blue: #08335E;
$as-grey-blue: #27466C;
$as-grey: #777777;

$transition-background-color:
  background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

// other colors
$color-background: #F8FBFD;

$color-white-text: rgb(250, 250, 250);

$color-very-light-grey-text: rgb(120, 120, 120);

$color-light-grey-border: rgb(220, 220, 220);
$color-light-grey-background: rgb(240, 240, 240);
$color-light-grey-text: rgb(80, 80, 80);

$color-dark-grey-border: rgb(180, 180, 180);
$color-dark-grey-text: rgb(60, 60, 60);
$color-dark-grey-background: rgb(200, 200, 200);

$box-shadow-color-1: rgba(65,69,73,0.3);
$box-shadow-color-2: rgba(65,69,73,0.15);
$box-shadow-color-3: rgba(65,69,73,0.10);

$box-shadow-full:
  0 1px 1px 0 $box-shadow-color-1,
  0 1px 3px 1px $box-shadow-color-2;
$box-shadow-light:
  0 1.5px 0 0 $box-shadow-color-3;

@mixin box-shadow-inner($size: 5px) {
  -webkit-box-shadow: inset 0 0 $size 0 rgba(0,0,0,0.75);
  -moz-box-shadow: inset 0 0 $size 0 rgba(0,0,0,0.75);
  box-shadow: inset 0 0 $size 0 rgba(0,0,0,0.75);
}

@mixin box-shadow-outer($size: 5px) {
  -webkit-box-shadow: 0 0 $size 0 rgba(0,0,0,0.75);
  -moz-box-shadow: 0 0 $size 0 rgba(0,0,0,0.75);
  box-shadow: 0 0 $size 0 rgba(0,0,0,0.75);
}

// **************** Layout *****************

$navbar-height: 4em;
$nav-drawer-width: 15em;
$default-border-radius: 0.75em;
$footer-height: 2em;

// **************** Scaling *****************

$windowed-max-width: 1270px;
$mobile-max-width: 750px;

$page-max-width: 60em;
