.checkout-page {
  /* for AddressEntry */
  .checkout-form-inner {
    padding: 1em 2em 2em 2em;
  }

  /* back to cart link */
  .back-to-cart-button {
    margin-bottom: 1em;
    // margin-left: 4em;
  }

  /* stepper */
  .checkout-stepper {
    .MuiStepper-root {
      padding: 0.35em;
    }
    .MuiPaper-root {
      background-color: transparent;
    }
    .MuiStepIcon-root.MuiStepIcon-completed {
      color: $as-blue;
    }
    .MuiStepIcon-root.MuiStepIcon-active {
      color: $as-blue;
    }
  }

  /* button at bottom */
  .checkout-button {
    margin-top: 1em;
    margin-bottom: 3em;
  }

  .MuiPaper-outlined {
    border: 2px solid black;
  }

  .checkout-complete-banner {
    .inner {
      padding: 1em 2em 1em 2em;
    }
  }
}

.saved-addresses {
  .MuiButton-root {
   text-transform: none;
   text-align: left;
   font-weight: normal;
   justify-content: flex-start;
   margin: 2px;
 }
 .MuiButton-outlinedPrimary {
   color:$as-blue;
   background-color: white;
   font-weight: bold;
   border: 2px solid;
   margin: 0px;
 }
}

.inline-edit-button {
  padding-left: 0.5em;
}

.message-banner {
  padding-bottom: 1em;
  margin: 0.5em;
}