.u-centerVertical {
  @include transform(translateY(-50%));

  position: absolute;
  top: 50%;
  width: 100%;
}

.u-centerVerticalRelative {
  vertical-align: middle;
  align-items: center;
}

.u-centerHorizontal {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.u-heightFull {
  height: 100%;
}

.u-widthFull {
  width: 100%;
}

.u-flexItem {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

.u-flexColumn {
  flex-direction: column;
}

.u-justifyCenter {
  justify-content: center;
}

.u-flexAlignItemsEnd {
  align-items: flex-end;
}

.u-flexAlignJustifyContentEnd {
  align-content: flex-end;
  justify-content: flex-end;
}

.u-flexSelfCenter {
  align-self: center;
}

.u-rotate90 {
  @include rotate(90deg);
}

.u-widthFitContent {
  width: fit-content !important;
}

.u-widthMaxContent {
  width: max-content !important;
}

$layout-sizes: 10;

@for $i from 0 through $layout-sizes {
  $size: $i * 0.5em;

  .u-margin-#{$i} { margin: $size; }
  .u-marginLeft-#{$i} { margin-left: $size; }
  .u-marginRight-#{$i} { margin-right: $size; }
  .u-marginTop-#{$i} { margin-top: $size; }
  .u-marginBottom-#{$i} { margin-bottom: $size; }
  .u-marginHorizontal-#{$i} { margin-left: $size; margin-right: $size; }
  .u-marginVertical-#{$i} { margin-top: $size; margin-bottom: $size;  }

  .u-padding-#{$i} { padding: $size; }
  .u-paddingLeft-#{$i} { padding-left: $size; }
  .u-paddingRight-#{$i} { padding-right: $size; }
  .u-paddingTop-#{$i} { padding-top: $size; }
  .u-paddingBottom-#{$i} { padding-bottom: $size; }
  .u-paddingHorizontal-#{$i} { padding-left: $size; padding-right: $size; }
  .u-paddingVertical-#{$i} { padding-top: $size; padding-bottom: $size; }

  .u-spacingVertical-#{$i} {
    & > * {
      margin-bottom: $size;

      &:last-child { margin-bottom: 0; }
    }
  }

  .u-spacingHorizontal-#{$i} {
    & > * {
      margin-right: $size;

      &:last-child { margin-right: 0; }
    }
  }

  .u-width-#{$i} { width: 1em * $i !important; }
}

.remove-link {
  text-decoration: none;
}

.color-white {
  color:white
}
