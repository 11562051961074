.blue-snackbar {
  .MuiSnackbarContent-root {
    background: $as-dark-blue;

    .MuiButton-label {
      color: white;
    }

    .closeButton {
      color: white;
    }
  }
}
