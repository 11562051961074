.solid-btn {
   color:white;
   background-color: #156cf0;
   border-radius: 0.5em;
}

.txt-btn {
   color: #156cf0;
   background-color: white;
   border-radius: 0.5em;
}