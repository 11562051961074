.order-item-table {
  // .order-item-row .MuiTableCell-root {
  //   padding-top: 0em 0em 1em 0em;
  // }
  .qty-field {
    width: 5em;
  }
}

.padded-divider {
  padding: 1em 0em 1em 0em;
}