.account-page {
  .order-history-paper {
    @media (min-width:600px) {
      padding: 1em 2em 2em 2em;
    }
    padding: 1em 1em 2em 1em;

    .orderRowInner {
      white-space: normal;
      word-break: break-word; 
      -webkit-hyphens:none;
      -moz-hyphens: none; 
      hyphens: none;
    }
  }
  .order-history-title {
    padding-top: 0.75em;
  }
}