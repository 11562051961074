$item-border: 0.05em solid $color-light-grey-border;
$item-transition-speed: 0.2s;

.category-view {
   display: 'flex';
   flex-wrap: 'wrap';
   justify-content: 'space-around';
   overflow: 'hidden';
}

.grid-item-display {
   height: 15em;
   min-width: 13em;
   width: 100%;

   justify-content: center;
   text-align: center;
   align-items: center;

   border: $item-border;
   margin: 0.3em;
   padding: 0.5em;
   transition: $item-transition-speed;
   cursor: pointer;
   background-color: $color-background;

   &:hover {
      box-shadow: $box-shadow-full;
   }
   
   .grid-item-display-image {
      height: 11em;
      width: 55%;
      
      //border: .05em solid $color-light-grey-border;
      //border-radius: 0.2em;
      
      overflow: hidden;
      vertical-align: middle;
      text-align:center;
      
      padding: 0.5em;
      margin: auto;
   }

   .card-actions {
      justify-content: center;
   }

   .item-display-subtitle-info {	
      display: block;	
   }
}