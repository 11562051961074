.header-title {
   height: 100%;
   cursor: pointer;
   display: flex;
   align-items: center;
}

.main-image {
   max-width: 100%;
   height: auto;

   justify-content: center;
   vertical-align: middle;
}

.nav-center {
   align-items: center;
   justify-content: center;
   text-align: center;
   vertical-align: middle;
}

.nav-search-bar {
   width: 60%;
   min-width: 12em;
}

.header-links {
   display: flex;
   height: 100%;
   align-items: center;
   justify-content: flex-end;
   align-content: flex-end;

   .link-item {
      margin: 0.3em;
   }

   .nav-btn {
      color:white;
      background-color: $as-blue;
      border-radius: 0.5em;
   }

   .sign-up-btn:hover {
      color: white;
      background-color: $as-dark-blue;
   }

   .nav-btn-txt {
      color: $as-blue;
      border-radius: 0.5em;
   }

   .txt-link {
      font-weight: bold;
      padding-top: 0.4em;
   }
}

.nav-root {
   display: flex;
}

.nav-color {
   color: #156cf0;
}

.nav-menu-button {
   margin-right: 1em;
}

.nav-category-button {
   margin: 0.2em;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   height: 100%;
   float: left;
   border-radius: 0px;
   color: #08335E;
}

.nav-toolbar {
   display: flex;
   width: 100%;
}

li.link-wrapper > a.active-page {
   width: 100%;
   height:100%;
   background-color: blue;
   font-weight: bold;
}

.grid-list {
   z-index: 100;
   border: 1px solid;
   background-color: white;
   width: fit-content;
}

.account-menu {
   z-index: 100;
}