.Footer {
   margin-top: 0.3em;
   display: flex;
   justify-content: center;
   align-items: center;
   // height: 3em;
   height: 6em;

   color: white;
   background: $as-dark-blue;

   .Footer-text {
      margin: 0.2em;
      text-align: center;
   }

   .Footer-link {
      color: #54a4ff;
   }
}

@media (min-width:600px) {
   .Footer {
      height: 3em;
   }
}