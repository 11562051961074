.cart-page {
  /* spacing to line up w/ checkouts */
  margin-top: 3.75em;
  margin-bottom: 2em;
}

.cart-image-container {
  height: 40px;
  width: 40px;
}

.cart-image-container img {
  width: 100%;
  height: 100%;
}

.cart-image {
  object-fit: contain;
}

/* green text */
.cart-price-text {
  color: #208710;
}

/* button above OrderSummary */
.checkout-side-button {
  margin-bottom: 1em !important;
}

/* OrderSummary */
.order-summary {
  .cart-right-inner {
    padding: 1em;
  }
  .cart-item-count {
    padding-left: 0.25em;
  }
  .cart-padding-top {
    padding-top: 1em;
  }
  .cart-padding-bottom {
    padding-bottom: 1em;
  }
}

.loading-screen {
  padding: 2em;
}

.quantityCell {
  @media (min-width:600px) {
    width: 125;
  }
  width: 100;
}

.quantityField {
  width: 100%;
}