body {
   margin: 0;
   font-family: Tahoma, Helvetica, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background-color: $color-background;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-offset {
   margin-top: 1.5em;

   display: block;

   justify-content: center;

   min-height: 100vh;
}

.title-format {
   margin: 2em;
}

.page-title {
   color: $as-blue;
}

.bright {
   color: $as-blue;
}
