@mixin rotate($degree) {
  -webkit-transform: rotate($degree); // Webkit-based
  -moz-transform: rotate($degree); //Firefox
  -ms-transform: rotate($degree); //IE
  -o-transform: rotate($degree); //Opera
  transform: rotate($degree); //Standard
}

@mixin transition($trans) {
  -webkit-transition: $trans; // Webkit-based
  -moz-transition: $trans; //Firefox
  -o-transition: $trans; //Opera
  transition: $trans; //Standard
}

@mixin transform($trans) {
  -webkit-transform: $trans; // Webkit-based
  -moz-transform: $trans; //Firefox
  -ms-transform: $trans; //IE
  -o-transform: $trans; //Opera
  transform: $trans; //Standard
}

// Box shadowing
@mixin box-shadow($shadow-value) {
  -webkit-box-shadow: $shadow-value;
  -moz-box-shadow: $shadow-value;
  box-shadow: $shadow-value;
}
