.ln-main {
   display: block;
   align-items: center;
   text-align: center;
   width: 100%;
   margin-bottom: 1em;

   .ln-logo {
      width: 100%;
      margin-bottom: 1.5em;
   }

   .ln-form {
      display: flex;
      justify-content: center;
      width: 100%;

      .ln-textBoxes {
         width: 90%;
         @media (min-width:600px) {
            width: 60%;
         }

         .ln-textBox {
            text-align: center;
            margin-bottom: 1em;
         }
      }

      .lockscreen-textBoxes {
         width: 40%;
         max-width: 20em;
         min-width: 14em;

         .ln-textBox {
            text-align: center;
            margin-bottom: 1em;
         }
      }
      
      .ln-emailField {
         width: 100%;
      }
      
      .ln-buttons {
         width: 100%;
      }
      
      .ln-button {
         width: 45%;
      }

      .ln-left-btn {
         float: left;
      }

      .ln-right-btn {
         float: right;
      }
      
      .ln-desc {
         width: 60%;
         margin: auto;
         margin-bottom: 1em;
      }
   }
}

.ln-alert {
   width: 50%;
   text-align: center;
   margin: auto;
}